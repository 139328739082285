@layer components {

    /**
        Værsågod custom Tailwind components
        https://tailwindcss.com/docs/adding-custom-styles#adding-component-classes
     */

    //.btn {
    //    @apply bg-blue m:bg-red;
    //}

    /** Text styles **/

    .text-display-1 {
        @apply font-serif text-36 m:text-60 l:text-96 leading-1 tracking-[0.3px] l:tracking-[1.5px];
    }

    .text-display-2 {
        @apply font-serif text-30 m:text-44 l:text-64 leading-1 tracking-[0.6px] l:tracking-[1.5px];
    }

    .text-h1 {
        @apply text-30 l:text-64 leading-1 -tracking-[0.8px] l:-tracking-[2px];
    }

    .text-h2 {
        @apply text-26 l:text-48 leading-1 -tracking-[0.8px] l:-tracking-[2px];
    }

    .text-h3 {
        @apply text-24 l:text-40 leading-1 -tracking-[0.4px] l:-tracking-[1px];
    }

    .text-paragraph {
        @apply text-20 l:text-32 leading-22 l:leading-38 -tracking-[0.5px];
    }

    .text-body-large {
        @apply text-16 l:text-24 leading-22 l:leading-32 -tracking-[0.3px];
    }

    .text-paragraph-then-body-large {
        @apply text-20 leading-22 l:text-24 l:leading-32 -tracking-[0.5px] l:-tracking-[0.3px];
    }

    .text-body-medium {
        @apply text-14 l:text-18 leading-20 l:leading-26 -tracking-[0.3px] l:-tracking-[0.2px];
    }

    .text-body-small {
        @apply text-14 l:text-16 leading-20 l:leading-22 tracking-[0.2px] l:tracking-normal;
    }

    .text-detail-large {
        @apply text-16 l:text-20 leading-1 tracking-[0.2px] l:tracking-normal;
    }

    .text-detail-medium {
        @apply text-14 l:text-16 leading-1 tracking-[0.2px] l:tracking-normal;
    }

    .text-detail-small {
        @apply font-medium text-12 l:text-14 leading-1 tracking-[0.2px] l:tracking-normal;
    }

    .text-detail-micro {
        @apply font-medium text-10 l:text-12 leading-1 tracking-[0.3px];
    }

    .text-badge-large {
        @apply font-medium uppercase text-16 l:text-18 leading-1 tracking-[0.3px];
    }

    .text-badge-small {
        @apply font-medium uppercase text-10 l:text-12 leading-1 tracking-[0.8px] l:tracking-[1px];
    }

    /** End Text styles **/

    .checkbox {
        input:focus + svg {
            outline: 5px auto Highlight;
            outline: 5px auto -webkit-focus-ring-color;
        }
        input:checked + svg path.hidden {
            @apply block;
        }
    }

    .map-shadow {
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
    }

    .pad-block {
        @apply py-40 l:py-64;

        &.has-border-top + &.has-border-top {
            @apply pt-0;
        }

        &.has-border-bottom + &.has-border-bottom {
            @apply pt-0;
        }

        &.has-border-bottom:last-child {
            @apply pb-0;
            & > * {
                @apply border-b-0;
            }
        }

        &--bottom {
            @apply pb-40 l:pb-64;
        }
    }

    .article-hero-landscape {
        overflow: hidden;
        clip-path: inset(var(--outer-margin));
        transition: clip-path 500ms theme('transitionTimingFunction.out-cubic');

        &__media {
            transition: transform 500ms theme('transitionTimingFunction.out-cubic');
        }
        &:hover &__media {
            transform: scale(1.05);
        }
        &:hover {
            clip-path: inset(0px);
        }
    }

    .store-header {
        transition: color 650ms theme('transitionTimingFunction.in-out-cubic');
        &.inverted {
            color: #ffffff;
        }
        &__image {
            opacity: 0;
            transition: opacity 650ms theme('transitionTimingFunction.in-out-cubic');
            .inverted & {
                opacity: 1;
            }
        }
        &__shader {
            opacity: 0;
            transition: opacity 650ms theme('transitionTimingFunction.in-out-cubic');
            .inverted & {
                opacity: 0.7;
            }
        }
        &__heading {
            transition: color 650ms theme('transitionTimingFunction.in-out-cubic');
            .inverted & {
                color: #ffffff;
            }
        }
        &__logo {
            transition: fill 450ms theme('transitionTimingFunction.in-out-cubic');
            .inverted & {
                fill: #ffffff;
            }
        }
        &__link {
            transition: border-color 350ms ease-out;
            border-color: theme('colors.ui.divider.DEFAULT');
            &:hover {
                border-color: theme('colors.ui.divider.tertiary');
            }
        }
    }
}


