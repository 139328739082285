.wysiwyg {
    p, ul, ol {
        margin-bottom: calc((26/18) * 1em);

        &:last-child {
            margin-bottom: 0;
        }
    }

    h2 {
        @apply text-body-large mb-8 l:mb-12;
    }

    h3 {
        @apply text-body-medium font-medium mb-6;
    }

    b, strong {
        @apply font-bold;
    }

    i, em {
        @apply italic;
    }

    ul {
        @apply pl-[1em] list-disc;
    }

    ol {
        @apply pl-[1em] list-decimal;
    }

    li {
        @apply list-item mb-[0.25em] last:mb-0;
    }

    a {
        @apply text-ui-fg-secondary decoration-ui-divider hocus:text-ui-fg hocus:decoration-ui-divider-tertiary underline underline-offset-4 transition-colors duration-350 ease-out;
    }
}
