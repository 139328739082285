@keyframes four-oh-four {
    from { transform: translateX(0); }
    to { transform: translateX(var(--translateX, -50%)); }
}

.four-oh-four {
    > * {
        animation: four-oh-four 10s infinite linear;
        @supports (--custom:property) {
            animation-duration: var(--duration, 15s);
        }
        @media screen and (prefers-reduced-motion: reduce), (update: slow) {
            animation: none;
        }
    }
}
