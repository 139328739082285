.mapboxgl-ctrl-group {
    display: flex;
    background: transparent;
    border-radius: 0;
    box-shadow: none !important;
}

.mapboxgl-ctrl-group button+button {
    border-top: 0 !important;
}

.mapboxgl-ctrl-group button {
    @apply !size-[32px] l:!size-[56px] transition-colors duration-350 ease-out map-shadow;
    border-radius: 8px !important;
    border: 0 !important;
    background: white;
    &:not(:last-child) {
        @apply mr-2 l:mr-4;
    }
}

.mapboxgl-ctrl button:not(:disabled):hover {
    @apply !bg-ui-bg-secondary;
}

.mapboxgl-ctrl button .mapboxgl-ctrl-icon {
    background-position: 50% 50%;
    @apply bg-[length:16px_16px] l:bg-[length:24px_24px];
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
    background-image: url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 8H2.5" stroke="%23514847" stroke-linecap="square" stroke-linejoin="round"/><path d="M8 2.5V13.5" stroke="%23514847" stroke-linecap="square" stroke-linejoin="round"/></svg>');
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
    background-image: url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 8H2.5" stroke="%23514847" stroke-linecap="square" stroke-linejoin="round"/></svg>');
}

.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate {
    @apply map-shadow;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate .mapboxgl-ctrl-icon {
    background-image: url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.1914 2.80859L13.1914 2.80859L13.1914 2.80859L2.60994 6.47141L7.39467 7.94363C7.55057 7.9916 7.69236 8.07697 7.8077 8.19231C7.92303 8.30764 8.0084 8.44943 8.05637 8.60533L9.52859 13.3901L13.1914 2.80859Z" stroke="%23514847" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}

.mapboxgl-popup {
    z-index: 3;
    width: calc(100% - 8px);
    max-width: 400px;
}

.mapboxgl-popup-content {
    background: transparent !important;
    padding: 0 !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    width: 100%;
    pointer-events: none;
    @apply ls:translate-x-[50%] pt:translate-y-[50%];
}

.mapboxgl-popup-tip {
    display: none !important;
}
